import React, { useContext, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FirebaseContext } from './Firebase';
import { Loader, Modal } from '@mantine/core';
import { navigate, Link } from 'gatsby';

const Forgot = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [wrongDetails, setWrongDetails] = useState(false);
  const { firebase } = useContext(FirebaseContext);
  const [succModal, setSuccModal] = useState(false);
  const { register, handleSubmit, errors } = useForm();

  const onSubmit = (form) => {
    setIsLoading(true);
    firebase
      .forgot({ email: form.email })
      .then(() => {
        setIsLoading(false);
        navigate('/profile');
      })
      .catch(() => {        
        setIsLoading(false);
        setWrongDetails(true);
      });
  };

  return (
    <section className='blog-details'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-8 offset-lg-2'>
            <div className='blog-one__single'>
              <div className='blog-one__content text-center'>
                <h2 className='blog-one__title'>
                  Reset Your Student Profile Password
                </h2>
                <form onSubmit={handleSubmit(onSubmit)} className='reply-form'>
                  <div className='row'>
                    <div className='col-lg-12'>
                      <input
                        type='email'
                        name='email'
                        placeholder='Enter email'
                        className='reply-form__field'
                        ref={register({ required: true })}
                      />
                      {errors.email && (
                        <span className='errorText'>
                          <i className='fas fa-exclamation-triangle'></i> This
                          field is required
                        </span>
                      )}
                      {wrongDetails && (
                        <span className='errorText'>
                          <i className='fas fa-question-circle'></i> An error
                          occurred, please try again
                        </span>
                      )}
                    </div>
                    <div className='col-lg-12'>
                      {isLoading ? (
                        <Loader size='xl' color='orange' variant='dots' />
                      ) : (
                        <button
                          className='reply-form__btn thm-btn'
                          type='submit'
                        >
                          Submit
                        </button>
                      )}
                      <Modal
                        onClose={() => setSuccModal(false)}
                        opened={succModal}
                      >
                        <div className='block-title text-left'>
                          <h2
                            className='block-title__title'
                            style={{ textAlign: 'center' }}
                          >
                            Success
                          </h2>
                        </div>
                        <p
                          className='about-two__text'
                          style={{ textAlign: 'center' }}
                        >
                          Reset password email successfully sent
                          <br />
                          <br />
                          <button
                            onClick={() => navigate('/login')}
                            className='contact-one__btn thm-btn'
                          >
                            Login
                          </button>
                        </p>
                      </Modal>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Forgot;
